import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import AuctionGridItem from './auctionGridItem';
import Loading from './loading';

function Auctions() {
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(null);
  let [auctionsList, setAuctionsList] = useState([]);
  let [auctionStatus, setAuctionStatus] = useState('Open');

  useEffect(() => {
    fetchAuctionsList();
  }, [])

  useEffect(() => {
    fetchAuctionsList(auctionStatus === 'Closed')
  }, [auctionStatus]);

  function fetchAuctionsList(closed) {
    let url = `https://y5pvoa70yl.execute-api.us-east-1.amazonaws.com${closed ? '?Status=Closed' : ''}`;

    fetch(url)
      .then(res => res.json())
      .then(res => {
        setLoading(false);
        setAuctionsList(res);
      }, err => {
        setLoading(false);
        setError('Something went wrong. Please try again.');
      });
  }

  function handleAuctionStatusChange(status) {
    if (status !== auctionStatus){
      setAuctionStatus(status);
    }
  }

  function auctionStatusFilter() {
    return (
      <div className="toggle-filter">
        <span className={auctionStatus === 'Open' ? 'active' : null} onClick={handleAuctionStatusChange.bind(this, 'Open')}>Open</span>
        <span className={auctionStatus === 'Closed' ? 'active' : null} onClick={handleAuctionStatusChange.bind(this, 'Closed')}>Closed</span>
      </div>
    );
  }


  return (
    <div className="auctions-index container">
      {
        loading ? <div className="mt-20"><Loading size="80" centered={true} /></div> :
          error ? <p className="mt-20">{error}</p> :
            !auctionsList.length ? <div className="mt-20"><p>There are currently no items for sale</p></div> :
              <div className="auctions-list-grid">
                {auctionsList.map((auction, i) => { return <AuctionGridItem key={i} auction={auction} />; })}
              </div>
      }
    </div>
  );
}

export default Auctions;




// <div className="auctions-filter-container">
//   <div className="auctions-filter-list">
//     <div className="auctions-filter">
//       <span className="auctions-filter-label">Status</span>
//       {auctionStatusFilter()}
//     </div>
//   </div>
// </div>
