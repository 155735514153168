import React, { useState, useEffect } from 'react';
import { useModalOpenEffect } from '../hooks/modal';
import { decimalCount, formatWithDoubleDecimal, priceString, currentBidLine } from '../../util/misc';
import AuctionImg from '../auctionImg';
import CloseSVG from '../svg/close';

function PlaceBid(props) {
  useModalOpenEffect();
  const [loading, setLoading] = useState(false);
  const [bidAmount, setBidAmount] = useState('');
  const [bidSuccessful, setBidSuccessful] = useState(false);

  useEffect(() => {
    if (loading) { submitBid(); }
  }, [loading]);

  function submitBid() {
    props.currentUser.getSession((_, session) => {
      fetch(`https://y5pvoa70yl.execute-api.us-east-1.amazonaws.com?AuctionID=${props.auction.AuctionID}&Username=${props.currentUser.username}&Action=Bid&BidAmount=${formatWithDoubleDecimal(bidAmount)}`,
        { method: 'PUT', headers: { 'Content-Type': 'application/json', 'Authorization': session.accessToken.jwtToken } })
          .then(res => res.json())
          .then(res => {
            props.reloadAuction();
            setBidSuccessful(true);
            setLoading(false);
          });
    });
  }

  function handleBidAmountChange(e) {
    setBidAmount(e.target.value);
  }

  function minimumBid() {
    if (props.auction.CurrentBid) {
      return props.auction.CurrentBid + props.auction.BidIncrement;
    } else {
      return props.auction.ReserveBid;
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
  }

  function disableSubmit() {
    if (!bidAmount) { return true; }
    if (decimalCount(bidAmount) > 2) { return true; }
    if (parseFloat(bidAmount) < minimumBid()) { return true; }
    return false;
  }


  return (
    <div className="modal xs-modal">
      <div className="modal-content">
        <div className="submit-bid-modal">
          <div className="flex-container mb-20">
            <div className="img-container small-img-container mr-10">
              <AuctionImg auction={props.auction} />
            </div>
            <div className="txt-left flex-1">
              <p className="txt-bold">{props.auction.Title}</p>
              <p>{currentBidLine(props.auction)}</p>
            </div>
          </div>
          {
            bidSuccessful ?
            <>
              <p className="txt-left mb-20">You've successfully submitted your bid for ${bidAmount}. You are the highest bidder!</p>
              <button className="btn" onClick={props.closeModal}>Done</button>
            </>:

            <form className="mb-30" onSubmit={handleSubmit}>
              <div className="input-wrapper">
                <p>Your bid ($USD)</p>
                <input type="number" value={bidAmount} onChange={handleBidAmountChange} />
                <p className="mt-5">Enter {priceString(minimumBid())} or more</p>
              </div>
              <div className="submit-bid-wrapper">
                <button className={`btn btn-bg${disableSubmit() ? ' disabled' : ''}`} onClick={handleSubmit}>Submit Bid</button>
              </div>
            </form>
          }
        </div>

        <CloseSVG onClick={props.closeModal} />
      </div>
    </div>
  );
}

export default PlaceBid;
