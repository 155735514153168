import React, { useState, useEffect } from 'react';
import { useModalOpenEffect } from '../hooks/modal';
import { decimalCount, priceString } from '../../util/misc';
import AuctionImg from '../auctionImg';
import CloseSVG from '../svg/close';

function BuyItNow(props) {
  useModalOpenEffect();
  const [buySuccessful, setBuySuccessful] = useState(false);

  function submitPurchase() {
    props.currentUser.getSession((_, session) => {
      fetch(`https://y5pvoa70yl.execute-api.us-east-1.amazonaws.com?AuctionID=${props.auction.AuctionID}&Action=Bid&BidAmount=${props.auction.BuyItNow}&Status=Closed`,
        { method: 'PUT', headers: { 'Content-Type': 'application/json', 'Authorization': session.accessToken.jwtToken } })
          .then(res => res.json())
          .then(res => {
            props.reloadAuction();
            setBuySuccessful(true);
          });
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    submitPurchase();
  }

  function disableSubmit() {
    return false;
  }

  if (buySuccessful) {
    return (
      <div className="modal xs-modal">
        <div className="modal-content">
          <div className="buy-it-now-modal">
            <p className="mb-20">Your purchase is complete!</p>
            <button className="btn" onClick={props.closeModal}>Done</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal large-modal">
      <div className="modal-content">
        <div className="buy-it-now-modal flex-container">
          <div className="buy-it-now-form">
               <form className="mb-30" onSubmit={handleSubmit}>
                 <p className="txt-bold mb-10">Shipping address</p>
                 <div className="input-wrapper input-wrapper-double">
                   <input type="text" placeholder="First name" />
                   <input type="text" placeholder="Last name" />
                 </div>
                 <div className="input-wrapper">
                   <input type="text" placeholder="Company (required for business addresses)" />
                 </div>
                 <div className="input-wrapper">
                   <input type="text" placeholder="Address" />
                 </div>
                 <div className="input-wrapper">
                   <input type="text" placeholder="Apartment, suite, etc. (optional)" />
                 </div>
                 <div className="input-wrapper">
                   <input type="text" placeholder="City" />
                 </div>
                 <div className="input-wrapper input-wrapper-triple">
                   <select><option>Country</option></select>
                   <select><option>State</option></select>
                   <input type="text" placeholder="ZIP code" />
                 </div>
                 <div className="input-wrapper">
                   <input type="text" placeholder="Phone (optional)" />
                 </div>
                 <div className="mt-30">
                   <a className="btn-link">CONTINUE TO SHIPPING</a>
                 </div>
               </form>
             </div>
              <div className="buy-it-now-submit">
                <div className="flex-container mb-20">
                  <div className="img-container small-img-container mr-10">
                    <AuctionImg auction={props.auction} />
                  </div>
                  <div className="txt-left flex-1">
                    <p className="txt-bold pr-10">{props.auction.Title}</p>
                  </div>
                  <div>
                    <p className="txt-bold mt-20">{priceString(props.auction.BuyItNow)}</p>
                  </div>
                </div>
                <div className="divide-line"></div>
                <div className="input-wrapper flex-container flex-align-center mb-20">
                  <input type="text" className="flex-1" placeholder="Gift card" />
                  <a className="btn-link ml-10">APPLY</a>
                </div>
                <div className="divide-line"></div>
                <div className="flex-container mb-10">
                  <p className="flex-1 txt-left">Subtotal</p>
                  <p className="txt-bold">{priceString(props.auction.BuyItNow)}</p>
                </div>
                <div className="flex-container flex-align-center mb-20">
                  <p className="flex-1 txt-left pr-20">**Expedited orders cannot be shipped or delivered on
                    weekends/holidays nor can they be shipped to a PO Box</p>
                  <p className="txt-sm">Calculated at next step</p>
                </div>
                <div className="divide-line"></div>
                <div className="flex-container mb-20">
                  <p className="flex-1 txt-bold txt-left">Total</p>
                  <h3 className="txt-bold">{priceString(props.auction.BuyItNow)}</h3>
                </div>
                <div className="txt-right">
                  <button className={`btn btn-bg${disableSubmit() ? ' disabled' : ''}`} onClick={handleSubmit}>COMPLETE PURCHASE</button>
                </div>
              </div>
            </div>

        <CloseSVG onClick={props.closeModal} />
      </div>
    </div>
  );
}

export default BuyItNow;


// {

//
//   <form className="mb-30" onSubmit={handleSubmit}>
//     <div className="input-wrapper">
//       <p>Email or username</p>
//       <input type="email" value={email} onChange={handleEmailChange} />
//     </div>
//     <div className="submit-bid-wrapper">
//       <button className={`btn btn-bg${disableSubmit() ? ' disabled' : ''}`} onClick={handleSubmit}>Confirm Purchase</button>
//     </div>
//   </form>
// }
