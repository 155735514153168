function isoToObj(s) {
  let b = s.split(/[-TZ:]/i);
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5]));
}

export function endsLineFull(time) {
  let d = isoToObj(time);
  let diff = d - new Date();

  diff = Math.abs(diff);

  let days = diff / 8.64e7 | 0;
  let hours = diff % 8.64e7 / 3.6e6 | 0;
  let mins = Math.round(diff % 3.6e6 / 6e4)

  return  `Ends ${d.toLocaleString()} (${days}d ${hours}h ${mins}m)`;
}

export function endsLineShort(time) {
  let d = isoToObj(time);
  let diff = d - new Date();

  diff = Math.abs(diff);

  let days = diff / 8.64e7 | 0;
  let hours = diff % 8.64e7 / 3.6e6 | 0;
  let mins = Math.round(diff % 3.6e6 / 6e4)

  return  `Ends in ${days}d ${hours}h ${mins}m`;
}

export function formattedTime(time) {
  let d = isoToObj(time);
  return d.toLocaleString();
}

export function bidsLine(auction) {
  if (!auction.Bids) { return '0 bids'; }
  let bidCount = auction.Bids.length;
  return `${bidCount} bid${bidCount > 1 ? 's' : ''}`;
}

export function decimalCount(number) {
  let split = number.split('.');
  if (split.length === 1) { return 0; }
  return split[1].length || 0;
}

export function formatWithDoubleDecimal(number) {
  let newNumber = number.slice();

  switch (decimalCount(number)) {
    case 0:
      return newNumber + '.00';
    case 1:
      return newNumber + '0';
    default:
      return newNumber;
  }
}

export function priceString(price) {
  let string = price.toString();
  let decimals = decimalCount(string);
  if (decimals === 0) { return `$${price}.00`; }
  if (decimals === 1) { return `$${price}0`; }
  return `$${price}`;
}

export function currentBidLine(auction) {
  let bidCount = (auction.Bids || []).length;
  let bidPrice = bidCount ? auction.CurrentBid : auction.ReserveBid;
  return `${priceString(bidPrice)} - ${bidCount} bid${bidCount === 1 ? '' : 's'}`;
}

export function winnerLine(auction) {
  if (!auction.Bids || !auction.Bids.length) { return null; }
  return <p className="txt-sm">Won by <span className="grey-txt">{auction.Bids[auction.Bids.length - 1].BidderID}</span></p>;
}

export function winnerLineFull(auction) {
  if (!auction.Bids || !auction.Bids.length) {
    return <p>There was no winner</p>;
  } else {
    return <p>Won by: <span>{auction.Bids[auction.Bids.length - 1].BidderID}</span></p>;
  }
}

export function winningLineFull(auction, selling, currentUser) {
  if (!auction.Bids || !auction.Bids.length) {
    return <p>There are no bids</p>;
  } else {
    let highest = auction.Bids[auction.Bids.length - 1].BidderID;

    if (selling) {
      return <p>Highest bidder: <span>{highest}</span></p>;
    } else {
      if (highest === currentUser) {
        return <p>You are the highest bidder</p>;
      } else {
        return <p>You are not the highest bidder</p>;
      }
    }
  }
}

export function currentUserWon(auction, currentUser) {
  if (!auction.Bids || !auction.Bids.length) { return false; }
  return auction.Bids[auction.Bids.length - 1].BidderID === currentUser;
}
