import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../images/logo.png';
import Profile from './svg/profile';
import DownCaret from './svg/downCaret';
import userpool from '../userpool';
import { AuthenticationContext } from '../services/authentication';

function Nav() {
  let [dropdownOpen, setDropdownOpen] = useState(false);
  let currentUser = userpool.getCurrentUser();
  const { logOut } = useContext(AuthenticationContext);

  function toggleDropdown() {
    setDropdownOpen(!dropdownOpen);
  }

  function profileDropdown() {
    return (
      <div className="nav-profile">
        <div className="flex-container flex-centered">
          <Profile />
          <DownCaret />
        </div>
        <ul className="profile-dropdown">
          <li><p><Link to="user/summary" className="txt-sm">Summary</Link></p></li>
          <li><p><Link to="edit-profile" className="txt-sm">Edit profile</Link></p></li>
          <li><p><a onClick={logOut} className="txt-sm">Log out</a></p></li>
        </ul>
      </div>
    );
  }

  return (
    <nav>
      <div className="container">
        <div className="flex-container">
          <div>
            <p><Link to="/" className="txt-bold">Home</Link></p>
          </div>
          <div className="nav-actions-container">
            {
              currentUser ?
                profileDropdown() :
                <p><Link to="sign-in" className="txt-bold">Sign in</Link> or <Link to="sign-up" className="txt-bold">Register</Link></p>
            }
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
