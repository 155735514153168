import React, { useEffect } from 'react';

export function useModalOpenEffect() {
  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';

    return function cleanup() {
      document.querySelector('body').style.overflow = null;
    }
  }, []);
}
