import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import PlaceBidModal from './modals/placeBid';
import BuyItNowModal from './modals/buyItNow';
import BidHistoryModal from './modals/bidHistory';
import BackIcon from './svg/back';
import Profile from './svg/profile';
import AuctionImg from './auctionImg';
import Loading from './loading';
import { endsLineFull, bidsLine, priceString, winnerLineFull } from '../util/misc';
import userpool from '../userpool';

function Auction() {
  let currentUser = userpool.getCurrentUser();
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(null);
  let [auction, setAuction] = useState(null);
  let [placeBidModal, setPlaceBidModal] = useState(false);
  let [buyItNowModal, setBuyItNowModal] = useState(false);
  let [bidHistoryModal, setBidHistoryModal] = useState(false);
  let params = useParams();

  useEffect(() => {
    if (loading) {
      fetchAuction();
    }
  }, [loading]);

  function fetchAuction() {
    fetch(`https://y5pvoa70yl.execute-api.us-east-1.amazonaws.com?AuctionID=${params.id}`)
      .then(res => res.json())
      .then(res => {
        setLoading(false);

        if (res.error) {
          setError(res.message);
        } else {
          setAuction(res);
        }
      });
  }

  function auctionOpen() {
    return auction.Status === 'Open';
  }

  function bidsLineEl() {
    let theLine = bidsLine(auction);

    if (auction.Bids && auction.Bids.length) {
      return <span className="link-with-underline" onClick={setBidHistoryModal.bind(this, true)}>{theLine}</span>;
    }

    return theLine;
  }


  function modals() {
    if (placeBidModal) {
      return <PlaceBidModal closeModal={setPlaceBidModal.bind(this, false)} auction={auction} reloadAuction={fetchAuction} currentUser={currentUser} />;
    } else if (buyItNowModal) {
      return <BuyItNowModal closeModal={setBuyItNowModal.bind(this, false)} auction={auction} reloadAuction={fetchAuction} currentUser={currentUser} />;
    } else if (bidHistoryModal) {
      return <BidHistoryModal closeModal={setBidHistoryModal.bind(this, false)} auction={auction} />;
    }
  }

  if (loading) {
    return (
      <div className="auction-page container">
        <Loading size="80" centered={true} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="auction-page container">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="auction-page container">
      <p className="mb-10"><Link className="link-with-underline" to="/"><span className="mr-5"><BackIcon /></span>Back to results</Link></p>
      <div className="flex-container-md">
        <div className="img-container auction-img-container">
          <AuctionImg auction={auction} />
        </div>
        <div className="auction-content">
          {
            auctionOpen() ? null :
              <p className="mb-10"><span className="status-label">Closed</span></p>
          }
          <h3 className="txt-bold mb-10">{auction.Title}</h3>
          <p className="mb-20">{auction.Description}</p>
          <div className="seller-container mb-20">
            <div className="seller-wrapper">
              <div className="flex-container">
                <Profile color="#888" />
                <span className="ml-5"><Link to={`/seller/${auction.SellerID}`} className="link-with-underline">{auction.SellerID}</Link></span>
              </div>
            </div>
          </div>
          <div className="auction-bids mb-20">
            <div className="bid-type">
              <h3 className="txt-bold">{priceString(auction.CurrentBid || auction.ReserveBid)}</h3>
              <p>{bidsLineEl()}</p>
              {
                auctionOpen() ?
                  <p className="grey-txt">{endsLineFull(auction.EndTime)}</p> :
                  winnerLineFull(auction)
              }
            </div>
            {
              !auctionOpen() ? null :
                <div className="bid-type">
                  <p>Buy It Now</p>
                  <h3 className="txt-bold">{priceString(auction.BuyItNow)}</h3>
                </div>
            }
          </div>
          {
            !auctionOpen() || !currentUser ? null :
              <div className="auction-actions">
                <div className="mb-10">
                  <button className="btn btn-bg" onClick={setPlaceBidModal.bind(this, true)}>Place Bid</button>
                </div>
                <div>
                  <Link className="btn btn-bg" to={`/item/${params.id}/buy-now`}>Buy It Now</Link>
                </div>
              </div>
          }
        </div>
      </div>
      {modals()}
    </div>
  );
}

export default Auction;
