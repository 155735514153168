import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Profile from './svg/profile';
import AuctionGridItem from './auctionGridItem';
import Loading from './loading';

function Seller() {
  let params = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (loading) { fetchUserItems(); }
  }, [loading]);

  function fetchUserItems() {
    fetch(`https://lv4rth3hwmaxcyhtj45erauncu0caspt.lambda-url.us-east-1.on.aws/?username=${params.id}`)
      .then(res => res.json())
      .then(res => {
        setItems(res);
        setLoading(false);
      }, err => {
        setError('Something went wrong. Please try again.');
        setLoading(false);
      });
  }


  return (
    <div className="seller-items">
      <div className="seller-header container">
        <Profile color="#888" size="80px" />
        <h1 className="mb-0">{params.id}</h1>
      </div>
      <div className="container">
      {
        loading ? <Loading size="80" centered={true} /> :
          error ? <p>{error}</p> :
            <>
              <h3>All items</h3>
                {
                  !items.length ?
                  <div className="mt-20">
                    <p>This user currently has no open auctions</p>
                  </div> :
                  <div className="auctions-list-grid">
                    {items.map((auction, i) => { return <AuctionGridItem key={i} auction={auction} />; })}
                  </div>
                }
            </>
      }
      </div>
    </div>
  );
}

export default Seller;
