import React, { useState, useEffect } from 'react';
import EditUserField from './editUserField';
import UpdateUserPhoto from '../modals/updateUserPhoto';
import UserImg from '../userImg';
import Loading from '../loading';
import userpool from '../../userpool';

function EditProfile() {
  const currentUser = userpool.getCurrentUser();
  const currentUsername = currentUser.getUsername();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState(currentUsername);
  const [givenName, setGivenName] = useState('');
  const [surname, setSurname] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [updatePhotoModal, setUpdatePhotoModal] = useState(false);

  useEffect(() => {
    fillInExistingData();
  },[])


  function fillInExistingData() {
    currentUser.getSession(_ => {});
    currentUser.getUserAttributes((_, attributes) => {
      if (attributes) {
        attributes.forEach(attribute => {
          if (attribute.Name === 'email') { setEmail(attribute.Value); }
          if (attribute.Name === 'given_name') { setGivenName(attribute.Value); }
          if (attribute.Name === 'family_name') { setSurname(attribute.Value); }
          if (attribute.Name === 'picture') { setImageUrl(attribute.Value); }
        });

      }

      setDataLoaded(true);
    });
  }

  function updatePhoto(e) {
    e.preventDefault();
    setUpdatePhotoModal(true);
  }

  return (
      <div className="authentication sign-up container">
        <h1>Edit Profile</h1>

        {
          !dataLoaded ? <Loading size="50" /> :
            <form>
              <div className="form-input-container">
                <label>Username</label>
                <p>{username}</p>
              </div>
              <div className="form-input-container">
                <label>Email</label>
                <p>{email}</p>
              </div>
              <EditUserField attribute="given_name" display="First Name (optional)" value={givenName} currentUser={currentUser} onSuccess={fillInExistingData} />
              <EditUserField attribute="family_name" display="Last Name (optional)" value={surname} currentUser={currentUser} onSuccess={fillInExistingData} />
              <div className="form-input-container">
                <label>Profile Picture</label>
                {
                  imageUrl ?
                    <div className="img-container user-img-container mb-10"><UserImg url={imageUrl} /></div> :
                    <p>You have not yet uploaded a profile picture</p>
                }
                <button className="btn mt-10" onClick={updatePhoto}>Update Image</button>
              </div>
          </form>
      }
      { updatePhotoModal ? <UpdateUserPhoto user={currentUser} closeModal={setUpdatePhotoModal.bind(this, false)} onSuccess={fillInExistingData} currentUser={currentUser} /> : null }
    </div>
  );
}

export default EditProfile;
