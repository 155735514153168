import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AuctionImg from './auctionImg';
import { endsLineShort, bidsLine, priceString, winnerLine } from '../util/misc';

function AuctionGridItem(props) {


  return (
    <div key={props.auction.AuctionID} className="auction-grid-item">
      <Link to={`/item/${props.auction.AuctionID}`}>
        <div className="img-container auction-item-img-container">
          <AuctionImg auction={props.auction} />
        </div>
      </Link>
      <div className="auction-item-content">
        {
          props.auction.Status === 'Closed' ? <p><span className="status-label-sm">Closed</span></p> : null
        }
        <h4 className="txt-bold"><Link to={`/item/${props.auction.AuctionID}`}>{props.auction.Title}</Link></h4>
        <div className="auction-item-bids">
          <div className="bid-type mb-10">
            <div className="flex-container mb-5">
              <p className="txt-bold mr-10">{priceString(props.auction.CurrentBid || props.auction.ReserveBid)}</p>
              <span className="grey-txt txt-sm">({bidsLine(props.auction)})</span>
            </div>
            {
              props.auction.Status === 'Closed' ? winnerLine(props.auction) :
                <p className="txt-sm grey-txt">{endsLineShort(props.auction.EndTime)}</p>
            }
          </div>
          { props.auction.Status === 'Closed' ? null :
              <div className="bid-type">
                <p className="txt-bold">{priceString(props.auction.BuyItNow)}</p>
                <p>Buy It Now</p>
              </div>
          }
        </div>
      </div>
    </div>
  );
}

export default AuctionGridItem;
