import React, { useState, useEffect, useRef } from 'react';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUser } from '../../slices/dreamfield';
import userpool from '../../userpool';

function SignUp() {
  let [loading, setLoading] = useState(false);
  let [success, setSuccess] = useState(false);
  let [errors, setErrors] = useState(null);
  let [email, setEmail] = useState('');
  let [username, setUsername] = useState('');
  let [password, setPassword] = useState('');
  let [givenName, setGivenName] = useState('');
  let [surname, setSurname] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading) { submitForm(); }
  }, [loading]);

  function handleEmailChange(e) {
    setEmail(e.target.value);
  }

  function handleUsernameChange(e) {
    setUsername(e.target.value);
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  function handleGivenNameChange(e) {
    setGivenName(e.target.value);
  }

  function handleSurnameChange(e) {
    setSurname(e.target.value);
  }

  // function handleSubmit(e) {
  //   e.preventDefault();
  //
  //   let formErrors = []
  //   if (!email || !email.length) { formErrors.push('Email is required'); }
  //   if (!password || !password.length) { formErrors.push('Password is required'); }
  //   if (!username || !username.length) { formErrors.push('Username is required'); }
  //   if (formErrors.length) { setErrors(formErrors); return false; }
  //
  //   let url = `https://yzo2zqqdpxgwkaqswbzzxxty3y0zqoeu.lambda-url.us-east-1.on.aws?email=${email}&username=${username}&password=${password}`;
  //   if (givenName && givenName.length) { url += `&given_name=${givenName}`; }
  //   if (surname && surname.length) { url += `&family_name=${surname}`; }
  //   if (phone && phone.length) { url += `&phone_number=${phone}`; }
  //   if (birthDate && birthDate.length) { url += `&birthdate=${birthDate}`; }
  //
  //   fetch(url, { method: 'POST' })
  //     .then(res => res.json())
  //     .then(res => {
  //       console.log(res);
  //     })
  // }

  function submitForm() {
    let formErrors = []
    if (!email || !email.length) { formErrors.push('Email is required'); }
    if (!password || !password.length) { formErrors.push('Password is required'); }
    if (!username || !username.length) { formErrors.push('Username is required'); }
    if (formErrors.length) { setErrors(formErrors); return false; }

    let attributeList = [new CognitoUserAttribute({ Name: 'email', Value: email })];
    if (givenName && givenName.length) { attributeList.push(new CognitoUserAttribute({ Name: 'given_name', Value: givenName })); }
    if (surname && surname.length) { attributeList.push(new CognitoUserAttribute({ Name: 'family_name', Value: surname })); }

    userpool.signUp(username, password, attributeList, null, (err, res) => {
      setLoading(false);

      if (err) {
        setErrors([err.message]);
      } else {
        setErrors(null);
        dispatch(setCurrentUser(res.user.username));
        setSuccess(true);
      }
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
  }

  function submitBtnKlass() {
    return loading || !email || !email.length || !password || !password.length || !username || ! username.length ? ' disabled' : '';
  }

  if (success) {
    return (
      <div className="authentication sign-up container">
        <h1>Account Created</h1>
        <p className="mb-30">Please confirm your signup. We've sent a validation link to your email.</p>
        <Link to="/sign-in" className="btn">Sign in</Link>
      </div>
    );
  }

  return (
      <div className="authentication sign-up container">
        <h1>Create an Account</h1>

        <form>
          {
            !errors || !errors.length ? null :
              <div>
                {
                  errors.map((error, i) => {
                    return <p key={i} className="red-txt mb-10">{error}</p>;
                  })
                }
              </div>
          }
          <div className="form-input-container">
            <label>Username</label>
            <input type="text" value={username} onChange={handleUsernameChange} />
          </div>
          <div className="form-input-container">
            <label>Email</label>
            <input type="text" value={email} onChange={handleEmailChange} />
          </div>
          <div className="form-input-container">
            <label>Password</label>
            <p className="txt-sm">(8 characters, 1 uppercase, 1 lowercase, 1 number)</p>
            <input type="password" value={password} onChange={handlePasswordChange} />
          </div>
          <div className="form-input-container">
            <label>First name (optional)</label>
            <input type="text" value={givenName} onChange={handleGivenNameChange} />
          </div>
          <div className="form-input-container">
            <label>Last name (optional)</label>
            <input type="text" value={surname} onChange={handleSurnameChange} />
          </div>

          <div className="form-input-container mt-30">
            <button className={`btn${submitBtnKlass()}`} onClick={handleSubmit}>Create Account</button>
          </div>


      </form>

    </div>
  );
}

export default SignUp;
