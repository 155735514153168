import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import AuctionImg from './auctionImg';
import { decimalCount, priceString } from '../util/misc';
import userpool from '../userpool';

function BuyItNow() {
  let currentUser = userpool.getCurrentUser();
  const [loading, setLoading] = useState(false);
  const [getError, setGetError] = useState(null);
  const [auction, setAuction] = useState(null);
  let params = useParams();

  useEffect(() => {
    if (!auction) { fetchAuction(); }
  }, []);

  // useEffect(() => {
  //   if (loading) { submitForm(); }
  // }, [loading]);


  function fetchAuction() {
    fetch(`https://y5pvoa70yl.execute-api.us-east-1.amazonaws.com?AuctionID=${params.id}`)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setGetError(res.message);
        } else {
          setAuction(res);
        }
      });
  }

  if (getError) {
    return (
      <div className="buy-it-now container">
        <div className="buy-it-now-content">
          <h1 className="mb-30">Buy It Now</h1>
          <p>{getError}</p>
        </div>
      </div>
    );
  }

  if (!auction) {
    return (
      <div className="buy-it-now container">
        <div className="buy-it-now-content">
        </div>
      </div>
    );
  }

  return (
    <div className="buy-it-now container">
      <div className="buy-it-now-content">
        <h1 className="mb-30">Buy It Now</h1>
        <div className="flex-container-lg">
          <div className="flex-1 mr-15 mb-15">
            <div className="buy-it-now-info flex-container mb-15">
              <div className="img-container small-img-container mr-10">
                <AuctionImg auction={auction} />
              </div>
              <div className="txt-left flex-1">
                <p className="txt-bold pr-10">{auction.Title}</p>
                <p className="txt-bold">{priceString(auction.BuyItNow)}</p>
              </div>
            </div>
            <div className="buy-it-now-address">
              <h4 className="txt-bold mb-20">Shipping Address</h4>
              <div className="input-wrapper input-wrapper-double">
                <input type="text" placeholder="First name" />
                <input type="text" placeholder="Last name" />
              </div>
              <div className="input-wrapper input-wrapper-double">
                <input type="text" placeholder="Address" />
                <input type="text" placeholder="Apartment, suite, etc. (optional)" />
              </div>
              <div className="input-wrapper input-wrapper-triple">
                <input type="text" placeholder="City" />
                <select><option>State</option></select>
                <input type="text" placeholder="ZIP code" />
              </div>
            </div>
          </div>
          <div className="buy-it-now-payment-form">
            <div className="buy-it-now-payment-form-container">
              <div className="flex-container justify-between">
                <p>Item</p>
                <p>{priceString(auction.BuyItNow)}</p>
              </div>
              <div className="flex-container justify-between mb-10">
                <p>Shipping</p>
                <p>$10.00</p>
              </div>
              <div className="flex-container justify-between total-price">
                <h4>Order Total</h4>
                <h3>{priceString(auction.BuyItNow + 10)}</h3>
              </div>
              <div>
                <input type="text" placeholder="card number" />
                <button className="btn btn-bg">Confirm and Pay</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyItNow;
