import React, { createContext } from 'react';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import userpool from '../userpool';

const AuthenticationContext = createContext();

function Authentication(props) {
  async function authenticate(email, password) {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: userpool
      });

      const authDetails= new AuthenticationDetails({
        Username: email,
        Password: password
      });

      user.authenticateUser(authDetails, {
        onSuccess: (result) => {
          resolve(result);
          window.location.href = '/';
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  };

  async function logOut() {
    const user = userpool.getCurrentUser();
    if (user) {
      user.signOut();
    }
    window.location.href = '/';
  };

  return (
    <AuthenticationContext.Provider value={{ authenticate, logOut }}>
      {props.children}
    </AuthenticationContext.Provider>
  );
}

export { Authentication, AuthenticationContext }

// export function authenticate(email, password) {
//   return new Promise((resolve, reject) => {
//     const user = new CognitoUser({
//       Username: email,
//       Pool: userpool
//     });
//
//     const authDetails= new AuthenticationDetails({
//       Username: email,
//       Password: password
//     });
//
//     user.authenticateUser(authDetails, {
//       onSuccess: (result) => {
//         resolve(result);
//         window.location.href = '/';
//       },
//       onFailure: (err) => {
//         reject(err);
//       }
//     });
//   });
// };
//
// export function logOut() {
//   const user = userpool.getCurrentUser();
//   if (user) {
//     user.signOut();
//   }
//   window.location.href = '/';
// };
//
// export function getSession() {
//   return new Promise((resolve, reject) => {
//     const user = userpool.getCurrentUser();
//     if (user) {
//       user.getSession((err, session) => {
//         if (err) { reject(); } else { resolve(session); }
//       });
//     } else {
//
//     }
//   });
// }
