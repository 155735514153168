import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SummaryAside from './summaryAside';
import Loading from '../loading';
import AuctionListItem from '../auctionListItem';
import userpool from '../../userpool';

function UserSelling() {
  let currentUser = userpool.getCurrentUser();
  const [loading, setLoading] = useState(true);
  const [selling, setSelling] = useState([]);
  const [sold, setSold] = useState([]);
  const [unsold, setUnsold] = useState([]);

  useEffect(() => {
    if (loading) { fetchSelling(); }
  }, [loading]);

  function parseData(data) {
    let newSelling = [];
    let newSold = [];
    let newUnsold = [];

    data.forEach((auction) => {
      if (auction.Status === 'Closed') {
        if (auction.Bids && auction.Bids.length) {
          newSold.push(auction);
        } else {
          newUnsold.push(auction);
        }
      } else {
        newSelling.push(auction);
      }
    });

    if (newSelling.length !== selling.length) { setSelling(newSelling); }
    if (newSold.length !== sold.length) { setSold(newSold); }
    if (newUnsold.length !== unsold.length) { setUnsold(newUnsold); }
  }

  function fetchSelling() {
    currentUser.getSession((_, session) => {
      fetch('https://iiw245eui8.execute-api.us-east-1.amazonaws.com/myAuctions',
        { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': session.accessToken.jwtToken } })
        .then(res => res.json())
        .then(res => {
          parseData(res);
          setLoading(false);
        });
    });
  }

  function itemList(items) {
    let itemEls = items.map((item, i) => {
      return <AuctionListItem key={i} auction={item} selling={true} />
    });

    return (
      <div className="auctions-list-list">
        {itemEls}
      </div>
    );
  }


  return (
    <div className="user-selling container">
      <h1 className="mb-0">{currentUser.username}</h1>
      <p className="mb-30"><Link to="/edit-profile" className="link-with-underline">Edit profile</Link></p>

      <div className="flex-container-lg">
        <SummaryAside />

        <div className="user-selling-main">
          <p className="mb-20">
            <Link to="/user/sell" className="btn-link">List an item</Link>
          </p>
          {
            loading ? <Loading size="50px" /> :
              <>
                <div>
                  <h2>Selling</h2>
                  {
                    selling.length ? itemList(selling) :
                      <p>You are currently not selling any items</p>
                  }
                </div>
                <div>
                  <h2>Sold</h2>
                  {
                    sold.length ? itemList(sold) :
                      <p>You have not sold any items</p>
                  }
                </div>
                <div>
                  <h2>Didn't sell</h2>
                  {
                    unsold.length ? itemList(unsold) :
                      <p >You do not have any unsold items</p>
                  }
                </div>
              </>
          }
        </div>
      </div>
    </div>
  );
}

export default UserSelling;
