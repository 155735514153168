import React, { useState, useEffect } from 'react';
import { useModalOpenEffect } from '../hooks/modal';
import logo from '../../images/logo.png';
import CloseSVG from '../svg/close';

function AcceptBeed(props) {
  useModalOpenEffect();
  let [transfered, setTransfered] = useState(false);

  useEffect(() => {
    if (!transfered) {
      setTimeout(() => {
        let transfer = document.getElementById('token-transfer');
        if (transfer) {
          transfer.classList.add('transforming');
        }
      }, 1000);

      setTimeout(() => {
        let transfer = document.getElementById('token-transfer');
        if (transfer) {
            transfer.classList.remove('transforming');
        }
        setTransfered(true);
      }, 4000);
    }
  }, [transfered])

  return (
    <div className="modal xs-modal">
      <div className="modal-content">
        <div className="accept-beed-modal">
          <div className="accept-beed-animation flex-container flex-align-center mb-20">
            <div className="img-container small-img-container">
              <img src={logo} width="100%" height="auto" />
            </div>
            <div>
              <p className="animation-token" id="token-transfer">1823fd239</p>
            </div>
            <div className="beed-recipient">
              <p>kobeshaq02</p>
            </div>
          </div>
          <p className="txt-bold txt-left mb-20">BTN 1823fd239</p>
          <p className="txt-left">
            The Beed<sup>TM</sup> token for your item is being transferred to you. You are now
            the registered owner of the item and the blockchain record of ownership.
            You can find your ownership status under the Ledger View by looking up
            the Beed<sup>TM</sup> Token Number.
          </p>
        </div>

        <CloseSVG onClick={props.closeModal} />
      </div>
    </div>
  );
}

export default AcceptBeed;
