import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { setCurrentUser } from '../../slices/dreamfield';
import { AuthenticationContext } from '../../services/authentication';

function SignIn() {
  let [loading, setLoading] = useState(false);
  let [errors, setErrors] = useState(null);
  let [username, setUsername] = useState('');
  let [password, setPassword] = useState('');
  // let dispatch = useDispatch();
  const { authenticate } = useContext(AuthenticationContext);

  useEffect(() => {
    if (loading) { submitForm(); }
  }, [loading]);

  function handleUsernameChange(e) {
    setUsername(e.target.value);
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  function submitForm() {
    authenticate(username, password)
      .then(res => {
        setLoading(false);
        setErrors(null);
      }, err => {
        setLoading(false);
        setErrors([err.message]);
      }).catch(err => {
        setLoading(false);
        setErrors([err.message])
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
  }

  function submitBtnKlass() {
    return loading || !username || !username.length || !password || !password.length ? ' disabled' : '';
  }

  return (
      <div className="authentication sign-in container">
        <h1>Sign In</h1>

        <form>
          {
            !errors || !errors.length ? null :
              <div>
                {
                  errors.map((error, i) => {
                    return <p key={i} className="red-txt mb-10">{error}</p>;
                  })
                }
              </div>
          }
          <div className="form-input-container">
            <label>Username</label>
            <input type="text" value={username} onChange={handleUsernameChange} />
          </div>
          <div className="form-input-container">
            <label>Password</label>
            <input type="password" value={password} onChange={handlePasswordChange} />
          </div>

          <div className="form-input-container mt-30">
            <button className={`btn${submitBtnKlass()}`} onClick={handleSubmit}>Sign In</button>
          </div>



        </form>
      </div>
  );
}

export default SignIn;
