import React, { useState, useEffect, useRef } from 'react';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';

function EditUserField(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [editing, setEditing] = useState(false);
  const [oldValue, setOldValue] = useState(props.value);
  const [newValue, setNewValue] = useState(props.value);

  useEffect(() => {
    if (loading) { submitField(); }
  }, [loading])

  useEffect(() => {
    setOldValue(props.value);
    setNewValue(props.value);
  }, [props.value])


  function submitField() {
    if (oldValue === newValue) { return false; }

    let newAttributes = [new CognitoUserAttribute({ Name: props.attribute, Value: newValue })];
    props.currentUser.getSession(_ => {});
    props.currentUser.updateAttributes(newAttributes, (err, res) => {
      setLoading(false);

      if (err) {
        setError(err);
      } else if (res) {
        setSuccess(true);
        toggleEditing();
        props.onSuccess();
      }
    });
  }

  function handleFieldChange(e) {
    setNewValue(e.target.value);
  }

  function toggleEditing(e) {
    if (e) { e.preventDefault(); }
    setError(null);
    if (!editing) { setSuccess(false); }
    setEditing(!editing);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
  }

  function submitBtnKlass() {
    return `btn${loading || (oldValue === newValue) ? ' disabled' : ''}`;
  }

  return (
    <div className="edit-user-field form-input-container flex-container">
      {
        editing ?
          <>
            <div>
              <label>{props.display}</label>
              { error ? <p className="red-txt">{error}</p> : null }
              <input type="text" value={newValue} onChange={handleFieldChange} className="mb-10" />
              <button onClick={toggleEditing} className="btn mr-10">Cancel</button>
              <button onClick={handleSubmit} className={submitBtnKlass()}>Save</button>
            </div>
          </> :
          <>
            <div>
              <label>{props.display}</label>
              { success ? <p className="green-txt">The update was successful</p> : null }
              <p>{oldValue.length ? oldValue : 'Please enter this data'}</p>
            </div>
            <button onClick={toggleEditing} className="btn btn-sm">Edit</button>
          </>
      }
    </div>
  );
}

export default EditUserField;
