import loading from '../images/loading.gif';

function Loading(props) {
  return (
    <div className={`loading-animation${props.centered ? ' centered' :''}`}>
      <img src={loading} height={props.size} width={props.size} />
    </div>
  );
}

export default Loading;
