import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SummaryAside from './summaryAside';
import Loading from '../loading';
import AuctionListItem from '../auctionListItem';
import userpool from '../../userpool';
import { currentUserWon } from '../../util/misc';

function UserSummary() {
  let currentUser = userpool.getCurrentUser();
  const [loading, setLoading] = useState(true);
  const [bidding, setBidding] = useState([]);
  const [purchased, setPurchased] = useState([]);
  const [lost, setLost] = useState([]);

  useEffect(() => {
    if (loading) { fetchSummary(); }
  }, [loading]);

  function parseData(data) {
    let newBidding = [];
    let newPurchased = [];
    let newLost = [];

    data.forEach((auction) => {
      if (auction.Status === 'Closed') {
        if (currentUserWon(auction, currentUser.username)) {
          newPurchased.push(auction);
        } else {
          newLost.push(auction);
        }
      } else {
        newBidding.push(auction);
      }
    });

    if (newBidding.length !== bidding.length) { setBidding(newBidding); }
    if (newPurchased.length !== purchased.length) { setPurchased(newPurchased); }
    if (newLost.length !== lost.length) { setLost(newLost); }
  }

  function fetchSummary() {
    currentUser.getSession((_, session) => {
      fetch('https://asvbo7hjai.execute-api.us-east-1.amazonaws.com/myBids',
        { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': session.accessToken.jwtToken } })
        .then(res => res.json())
        .then(res => {
          parseData(res);
          setLoading(false);
        });
    });
  }

  function itemList(items, location) {
    let itemEls = items.map((item, i) => {
      return <AuctionListItem key={i} auction={item} location={location || null} />
    });

    return (
      <div className="auctions-list-list">
        {itemEls}
      </div>
    );
  }


  return (
    <div className="user-summary container">
      <h1 className="mb-0">{currentUser.username}</h1>
      <p className="mb-30"><Link to="/edit-profile" className="link-with-underline">Edit profile</Link></p>

      <div className="flex-container-lg">
        <SummaryAside />

        <div className="user-summary-main">
          {
            loading ? <Loading size="50px" /> :
              <>
                <div>
                  <h2>Bidding</h2>
                  {
                    bidding.length ?  itemList(bidding) :
                      <p>You are currently not bidding on any items</p>
                  }
                </div>
                <div>
                  <h2>Purchased / Won</h2>
                  {
                    purchased.length ? itemList(purchased, 'purchased') :
                      <p>You have not purchased or won any items</p>
                  }
                </div>
                <div>
                  <h2>Didn't win</h2>
                  {
                    lost.length ? itemList(lost) :
                      <p>You have not lost any auctions</p>
                  }
                </div>
              </>
            }
        </div>
      </div>
    </div>
  );
}

export default UserSummary;
