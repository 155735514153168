function DownCaret(props) {
  return (
    <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none">
      <g>
        <path d="M19.1416 9.92896L12.7776 16.2929C12.3871 16.6834 11.7539 16.6834 11.3634 16.2929L4.99946 9.92895" stroke="#eee" strokeLinecap="round"/>
      </g>
    </svg>
  );
}

export default DownCaret;
