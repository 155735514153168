import React, { useState, useEffect } from 'react';
import S3FileUpload from 'react-s3';
import CloseSVG from '../svg/close';
import { useModalOpenEffect } from '../hooks/modal';
window.Buffer = window.Buffer || require("buffer").Buffer;

function UpdateAuctionPhoto(props) {
  useModalOpenEffect();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (loading) { uploadImage(); }
  }, [loading]);

  function updateAuction(location) {
    let url = `https://y5pvoa70yl.execute-api.us-east-1.amazonaws.com?AuctionID=${props.auction.AuctionID}&Action=Update&ImageUrl=${location}`;

    props.currentUser.getSession((_, session) => {
      fetch(url, { method: 'PUT', headers: { 'Content-Type': 'application/json', 'Authorization': session.accessToken.jwtToken } })
        .then(res => res.json())
        .then(res => {
          setLoading(false);
          setSuccess(true);
          setError(null);
          props.onSuccess();
        });
    });
  }

  function uploadImage() {
    fetch('https://yujcksltgl.execute-api.us-east-1.amazonaws.com/AccessS3')
      .then(res => res.json())
      .then(res => {
        const config = {
            bucketName: 'beed-auction-photos',
            dirName: props.auction.Folder.replace('/', ''),
            region: 'us-east-1',
            accessKeyId: res.accessKeyId,
            secretAccessKey: res.secretAccessKey
        };

        S3FileUpload.uploadFile(file, config)
          .then(data => {
            updateAuction(data.location);
          }).catch(err => setError(err));
      });
  }

  function handleFileChange(e) {
    let newFile = e.target.files[0];
    let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    if (allowedExtensions.exec(newFile.name)) {
      setFile(e.target.files[0]);
      setError(null);
    } else {
      setError('Only jpg, jgeg, and png files are supported');
      e.target.value = null;
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
  }

  function disableSubmit() {
    return !file || loading;
  }


  return (
    <div className="modal xs-modal">
      <div className="modal-content">
        <div className="update-photo-modal">
          { error ? <p className="red-txt mb-10">{error}</p> : null }
          { success ? <p className="green-txt mb-10">You have successully updated your auction image</p> : null }
          <div className="form-input-container mb-10">
            <input type="file" onChange={handleFileChange} />
          </div>
          <button className={`btn${disableSubmit() ? ' disabled' : ''}`} onClick={handleSubmit}>Upload</button>
        </div>

        <CloseSVG onClick={props.closeModal} />
      </div>
    </div>
  );
}

export default UpdateAuctionPhoto;
