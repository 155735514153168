import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import UpdateAuctionPhoto from './modals/updateAuctionPhoto';
import AuctionImg from './auctionImg';
import userpool from '../userpool';

function EditAuction() {
  let currentUser = userpool.getCurrentUser();
  const [loading, setLoading] = useState(false);
  const [getError, setGetError] = useState(null);
  const [putError, setPutError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [auction, setAuction] = useState(null);
  const [title, setTitle] = useState({ old: '', new: '' });
  const [description, setDescription] = useState({ old: '', new: '' });
  const [startingBid, setStartingBid] = useState({ old: '0', new: '' });
  const [buyItNow, setBuyItNow] = useState({ old: '0', new: '' });
  const [bidIncrement, setBidIncrement] = useState({ old: '0', new: '' });
  const [putSuccessful, setPutSuccessful] = useState(false);
  const [updatePhotoModal, setUpdatePhotoModal] = useState(false);
  let params = useParams();

  useEffect(() => {
    if (!auction) { fetchAuction(); }
  }, []);

  useEffect(() => {
    if (auction) {
      fillInExistingData(auction);
    }
  }, [auction]);

  useEffect(() => {
    if (loading) { submitForm(); }
  }, [loading]);

  function fillInExistingData(auction) {
    for (const attribute in auction) {
      switch (attribute) {
        case 'Title':
          setTitle({ old: auction[attribute], new: auction[attribute] }); break;
        case 'Description':
          setDescription({ old: auction[attribute], new: auction[attribute] }); break;
        case 'ReserveBid':
          setStartingBid({ old: auction[attribute], new: auction[attribute] }); break;
        case 'BuyItNow':
          setBuyItNow({ old: auction[attribute], new: auction[attribute] }); break;
        case 'BidIncrement':
          setBidIncrement({ old: auction[attribute], new: auction[attribute] }); break;
      }
    }

    setLoading(false);
  }

  function fetchAuction() {
    currentUser.getSession((_, session) => {
      fetch(`https://iiw245eui8.execute-api.us-east-1.amazonaws.com/myAuctions?AuctionID=${params.id}`,
        { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': session.accessToken.jwtToken } })
          .then(res => res.json())
          .then(res => {
            if (res.error) {
              setGetError(res.message);
            } else {
              setAuction(res[0]);
            }
          })
    });
  }

  function handleTitleChange(e) {
    setTitle({ old: title.old, new: e.target.value });
  }

  function handleDescriptionChange(e) {
    setDescription({ old: description.old, new: e.target.value });
  }

  function handleStartingBidChange(e) {
    setStartingBid({ old: startingBid.old, new: e.target.value });
  }

  function handleBuyItNowChange(e) {
    setBuyItNow({ old: buyItNow.old, new: e.target.value });
  }

  function handleBidIncrementChange(e) {
    setBidIncrement({ old: bidIncrement.old, new: e.target.value });
  }

  function submitForm() {
    let url = `https://y5pvoa70yl.execute-api.us-east-1.amazonaws.com?AuctionID=${auction.AuctionID}&Action=Update`;
    if (title.old !== title.new) { url += `&Title=${title.new}`; }
    if (description.old !== description.new) { url += `&Description=${description.new}`; }
    if (startingBid.old !== startingBid.new) { url += `&ReserveBid=${startingBid.new}`; }
    if (buyItNow.old !== buyItNow.new) { url += `&BuyItNow=${buyItNow.new}`; }
    if (bidIncrement.old !== bidIncrement.new) { url += `&BidIncrement=${bidIncrement.new}`; }

    currentUser.getSession((_, session) => {
      fetch(url, { method: 'PUT', headers: { 'Content-Type': 'application/json', 'Authorization': session.accessToken.jwtToken } })
        .then(res => res.json())
        .then(res => {
          setSuccess(true);
          setLoading(false);
        });
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
  }

  function disableSubmit() {
    return loading;
  }

  function updatePhoto(e) {
    e.preventDefault();
    setUpdatePhotoModal(true);
  }

  if (getError) {
    return (
      <div className="sell-page container">
        <div className="sell-page-content">
          <h1 className="mb-30">Edit Item</h1>
          <p>{getError}</p>
        </div>
      </div>
    );
  }

  if (!auction) {
    return (
      <div className="sell-page container">
        <div className="sell-page-content">
        </div>
      </div>
    );
  }

  return (
    <div className="sell-page container">
      <div className="sell-page-content">
        <h1 className="mb-30">Edit Auction</h1>
        <div className="mb-20"><Link to={`/item/${auction.AuctionID}`} className="btn-link" target="_blank">View Auction</Link></div>
        <form>
          { !success ? null :
              <div className="mb-10"><p className="green-txt">You have successfully updated your listing</p></div>
          }
          { !putError ? null :
              <div className="mb-10"><p className="red-txt">{putError}</p></div>
          }
          <div className="form-input-container">
            {
              !auction.ImageUrl ? null : <div className="img-container auction-img-container mb-10"><AuctionImg auction={auction} /></div>
            }
            <button className="btn" onClick={updatePhoto}>Update Image</button>
          </div>
          <div className="form-input-container">
            <label>Title</label>
            <input type="text" value={title.new} onChange={handleTitleChange} />
          </div>
          <div className="form-input-container">
            <label>Description</label>
            <textarea rows="5" value={description.new} onChange={handleDescriptionChange} />
          </div>
          <div className="form-input-container">
            <label>Starting Bid</label>
            <input type="number" value={startingBid.new} onChange={handleStartingBidChange} />
          </div>
          <div className="form-input-container">
            <label>Buy It Now Price</label>
            <input type="number" value={buyItNow.new} onChange={handleBuyItNowChange} />
          </div>
          <div className="form-input-container">
            <label>Bid Increment</label>
            <input type="number" value={bidIncrement.new} onChange={handleBidIncrementChange} />
          </div>
          <div className="form-input-container">
            <button className={`btn${disableSubmit() ? ' disabled' : ''}`} onClick={handleSubmit}>Save</button>
          </div>
        </form>
      </div>

      { updatePhotoModal ? <UpdateAuctionPhoto auction={auction} closeModal={setUpdatePhotoModal.bind(this, false)} onSuccess={fetchAuction} currentUser={currentUser} /> : null }
    </div>
  );
}

export default EditAuction;
