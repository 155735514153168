import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import Nav from './nav';
import Auctions from './auctions';
import Auction from './auction';
import Sell from './user/sell';
import EditAuction from './editAuction';
import BuyItNow from './buyItNow';
import SubmitPayment from './submitPayment';
import SignIn from './auth/signIn';
import SignUp from './auth/signUp';
import EditUser from './auth/editUser';
import UserSummary from './user/summary';
import UserSelling from './user/selling';
import Seller from './seller';
import PurchaseHistory from './user/purchaseHistory';
import { Authentication } from '../services/authentication';

function App() {

  return (
    <Authentication>
      <div className="beed">
        <div className="app-main">
          <main className="main-content">
            <Nav />
            <Routes>
              <Route index element={<Auctions />} />
              <Route path="sign-in" element={<SignIn />} />
              <Route path="sign-up" element={<SignUp />} />
              <Route path="user">
                <Route path="summary" element={<PrivateRoute element={<UserSummary />} />} />
                <Route path="selling" element={<PrivateRoute element={<UserSelling />} />} />
                <Route path="sell" element={<PrivateRoute element={<Sell />} />} />
              </Route>
              <Route path="edit-profile" element={<PrivateRoute element={<EditUser />} />} />
              <Route path="item">
                <Route path=":id" element={<Auction />} />
                <Route path=":id/edit" element={<EditAuction />} />
                <Route path=":id/buy-now" element={<BuyItNow />} />
                <Route path=":id/submit-payment" element={<SubmitPayment />} />
              </Route>
              <Route path="seller/:id" element={<Seller />} />
              <Route path="purchasehistory" element={<PurchaseHistory />} />
            </Routes>
          </main>
        </div>
      </div>
    </Authentication>
  );
}

export default App;
