import { createSlice } from '@reduxjs/toolkit';

export const dreamfieldSlice = createSlice({
  name: 'dreamfield',
  initialState: {
    currentUser: null
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
    }
  }
});

export const { setCurrentUser } = dreamfieldSlice.actions;
export default dreamfieldSlice.reducer;
