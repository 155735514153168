import React, { useState, useEffect } from 'react';
import { useModalOpenEffect } from '../hooks/modal';
import { priceString, currentBidLine, formattedTime } from '../../util/misc';
import AuctionImg from '../auctionImg';
import CloseSVG from '../svg/close';

function BidHistory(props) {
  useModalOpenEffect();

  function blockedUsername(username) {
    return `${username[0]}${'*'.repeat(username.length - 2)}${username[username.length -1]}`
  }

  function historyRows() {
    let bids = props.auction.Bids.slice().reverse();
    return bids.map((bid, i) => {
      return (
        <tr className={i === 0 ? 'txt-bold' : ''} key={i}>
          <td>{blockedUsername(bid.BidderID)}</td>
          <td>{priceString(bid.BidAmount)}</td>
          <td>{formattedTime(bid.TimeStamp)}</td>
        </tr>
      );
    });
  }

  return (
    <div className="modal small-modal">
      <div className="modal-content">
        <div className="bid-history-modal">
          <h1 className="mb-20">Bid History</h1>
          <div className="flex-container flex-centered auction-header-info mb-20">
            <div className="img-container small-img-container mr-10">
              <AuctionImg auction={props.auction} />
            </div>
            <div className="txt-left">
              <p>{props.auction.Title}</p>
              <p className="mb-20">{currentBidLine(props.auction)}</p>
            </div>
          </div>

          <table>
            <thead><tr className="txt-bold"><td>Bidder</td><td>Bid Amount</td><td>Bid Time</td></tr></thead>
            <tbody>
              {historyRows()}
            </tbody>
          </table>

          <div className="mt-20">
            <button className="btn" onClick={props.closeModal}>Close</button>
          </div>
        </div>

        <CloseSVG onClick={props.closeModal} />
      </div>
    </div>
  );
}

export default BidHistory;
