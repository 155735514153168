import logo from '../images/logo.png';


function AuctionImg(props) {

  if (props.auction.ImageUrl) {
    return (
      <img className="auction-img" src={props.auction.ImageUrl} width="100%" height="100%" />
    );
  } else {
    return (
      <img className="auction-img" src={logo} width="100%" height="100%" />
    );
  }

}

export default AuctionImg;
