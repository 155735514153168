import React, { useState } from 'react';
import AcceptBeedModal from '../modals/acceptBeed';
import AuctionImg from '../auctionImg';

function PurchaseHistory() {
  let [acceptBeedModal, setAcceptBeedModal] = useState(false);
  let ORDERS = [
    {
      date: 'December 7, 2023',
      orderNumber: '64-2809-6234',
      listingNumber: '2390823491',
      seller: 'warriors30',
      title: 'Michael Jordan Autographed Basketball',
      orderTotal: 'USD $650.00',
      itemPrice: 'USD $625.00',
      Slug: 'Michael-Jordan-Basketball.png'
   },
   {
     date: 'October 18, 2023',
     orderNumber: '64-2801-0938',
     listingNumber: '2390822063',
     seller: 'baller88',
     title: 'Cooper Kupp Game Worn Jersey',
     orderTotal: 'USD $620.00',
     itemPrice: 'USD $600.00'
  }
];

  function orderList() {
    return ORDERS.map(order => {
      return (
        <div className="order-item" key={order.listingNumber}>
          <div className="order-item-details">
            <div className="order-meta flex-container mb-20">
              <div className="mr-30">
                <p className="grey-txt txt-sm">ORDER DATE</p>
                <p className="txt-sm">{order.date}</p>
              </div>
              <div className="mr-30">
                <p className="grey-txt txt-sm">ORDER NUMBER</p>
                <p className="txt-sm">{order.orderNumber}</p>
              </div>
              <div>
                <p className="grey-txt txt-sm">SOLD BY</p>
                <p className="txt-sm">{order.seller}</p>
              </div>
            </div>
            <div className="flex-container mb-20">
              <div className="img-container small-img-container mr-10">
                <AuctionImg auction={order} />
              </div>
              <div className="txt-left">
                <p>{order.title}</p>
                <p className="grey-txt mb-10">{order.listingNumber}</p>
                <p className="txt-bold">This item has been shipped.</p>
              </div>
            </div>
          </div>
          <div className="order-item-cost">
            <p className="txt-sm">ORDER TOTAL</p>
            <p className="txt-bold mb-30">{order.orderTotal}</p>
            <p className="txt-sm">ITEM PRICE</p>
            <p className="txt-bold">{order.itemPrice}</p>
          </div>
          <div className="order-item-actions">
            <div><a href="#" className="btn-link btn-reverse">Return this item</a></div>
            <div><a href="#" className="btn-link btn-reverse">View similar items</a></div>
            <div><a href="#" className="btn-link btn-reverse">Leave feedback</a></div>
            <div><button className="btn" onClick={setAcceptBeedModal.bind(this, true)}>Accept Beed<sup>TM</sup></button></div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="purchase-history container flex-container">
      <div className="purchase-history-aside">
        <p><a href="#">Summary</a></p>
        <p><a href="#">Revently viewed</a></p>
        <p><a href="#">Bids/Offers</a></p>
        <p className="active"><a href="#">Purchase history</a></p>
        <p><a href="#">Watching</a></p>
        <p><a href="#">Saved searches</a></p>
        <p><a href="#">Saved sellers</a></p>
      </div>
      <div className="purchase-history-main">
        <h3 className="txt-bold mb-20">Orders</h3>
        <div className="order-list">
          {orderList()}
        </div>
      </div>
      { acceptBeedModal ? <AcceptBeedModal closeModal={setAcceptBeedModal.bind(this, false)}/> : null }
    </div>
  );
}

export default PurchaseHistory;
