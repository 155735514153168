import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import userpool from '../userpool';

function PrivateRoute(props) {
  const currentUser = userpool.getCurrentUser();

  if (currentUser) {
    return <>{props.element}</>;
  } else {
    return <Navigate to="/sign-in" />;
  }
}

export default PrivateRoute;
