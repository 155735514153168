import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AuctionImg from './auctionImg';
import { endsLineShort, bidsLine, priceString, winnerLineFull, winningLineFull } from '../util/misc';
import userpool from '../userpool';

function AuctionListItem(props) {
  let currentUser = userpool.getCurrentUser();

  function statusLine() {
    if (props.auction.Status === 'Closed') {
      return winnerLineFull(props.auction);
    } else {
      return winningLineFull(props.auction, props.selling, currentUser.username);
    }
  }


  return (
    <div key={props.auction.AuctionID} className="auction-list-item">
      <Link to={`/item/${props.auction.AuctionID}`}>
        <div className="img-container auction-item-img-container">
          <AuctionImg auction={props.auction} />
        </div>
      </Link>
      <div className="flex-container-md auction-item-content-bids-actions">
        <div className="auction-item-content">
          { props.auction.Status === 'Closed' ? <p className="mb-5"><span className="status-label-sm">Closed</span></p> : null }
          <h4 className="txt-bold mb-5"><Link to={`/item/${props.auction.AuctionID}`}>{props.auction.Title}</Link></h4>
          <p>Seller: <Link className="link-with-underline" to={`/seller/${props.auction.SellerID}`}>{props.auction.SellerID}</Link></p>
          {statusLine()}
        </div>
        <div className="auction-item-bids">
          <div className="bid-type mb-10">
            <div className="flex-container mb-5">
              <p className="txt-bold mr-10">{priceString(props.auction.CurrentBid || props.auction.ReserveBid)}</p>
              <span className="grey-txt txt-sm">({bidsLine(props.auction)})</span>
            </div>
            {
              props.auction.Status === 'Closed' ? null :
                <p className="txt-sm grey-txt">{endsLineShort(props.auction.EndTime)}</p>
            }
          </div>
          { props.auction.Status === 'Closed' ? null :
              <div className="bid-type">
                <p className="txt-bold">{priceString(props.auction.BuyItNow)}</p>
                <p>Buy It Now</p>
              </div>
          }
        </div>
        <div className="auction-item-actions">
          {
            !props.selling || props.auction.Status === 'Closed' ? null :
              <Link to={`/item/${props.auction.AuctionID}/edit`} className="btn-link">Edit</Link>
          }
          {
            props.location !== 'purchased' ? null :
              <Link to={`/item/${props.auction.AuctionID}/submit-payment`} className="btn-link">Pay</Link>
          }
        </div>
      </div>
    </div>
  );
}

export default AuctionListItem;
